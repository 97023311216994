/* global axios */
import ApiClient from './ApiClient';

class CasesAPI extends ApiClient {
  constructor() {
    super('cases', { accountScoped: true });
  }

  getContactCases(contact_id) {
    let result = axios.post(`${this.url}/contact/`, { contact_id });
    return result;
  }
}

export default new CasesAPI();
