import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import CasesAPI from '../../api/cases';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { CASES_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  records: [],
  contact_cases: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isLoadingContactCases: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getCases: _state => {
    return _state.records.sort((c2, c1) =>
      c1.created_at?.localeCompare(c2.created_at)
    );
  },
  getCase: _state => caseId => {
    const result = _state.records.filter(record => record.id === caseId);
    return (result && result[0]) || {};
  },
  getAllCases: _state => {
    return _state.records;
  },
  getCurrentContactCases: _state => {
    return _state.contact_cases.sort((c2, c1) =>
      c1.created_at?.localeCompare(c2.created_at)
    );
  },
};

export const actions = {
  get: async function getCases({ commit }) {
    commit(types.SET_CASE_UI_FLAG, { isFetching: true });
    try {
      const response = await CasesAPI.get();
      commit(types.SET_CASES, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_CASE_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createCases({ commit }, caseObj) {
    commit(types.SET_CASE_UI_FLAG, { isCreating: true });
    try {
      const response = await CasesAPI.create(caseObj);
      commit(types.ADD_CASE, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CASE_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_CASE_UI_FLAG, { isUpdating: true });
    try {
      const response = await CasesAPI.update(id, updateObj);
      AnalyticsHelper.track(CASES_EVENTS.UPDATE_CASE);
      commit(types.EDIT_CASE, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CASE_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_CASE_UI_FLAG, { isDeleting: true });
    try {
      await CasesAPI.delete(id);
      AnalyticsHelper.track(CASES_EVENTS.DELETE_CASE);
      commit(types.DELETE_CASE, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CASE_UI_FLAG, { isDeleting: false });
    }
  },
  getContactCases: async ({ commit }, contact_id) => {
    commit(types.SET_CASE_UI_FLAG, { isLoadingContactCases: true });
    try {
      commit(types.CLEAR_CONTACT_CASES);
      const response = await CasesAPI.getContactCases(contact_id);
      commit(types.SET_CONTACT_CASES, response);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_CASE_UI_FLAG, { isLoadingContactCases: false });
    }
  },
};

export const mutations = {
  [types.SET_CASE_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_CASE]: MutationHelpers.create,
  [types.SET_CASES]: MutationHelpers.set,
  [types.EDIT_CASE]: MutationHelpers.update,
  [types.DELETE_CASE]: MutationHelpers.destroy,

  [types.SET_CONTACT_CASES](_state, { data }) {
    _state.contact_cases = data;
  },
  [types.CLEAR_CONTACT_CASES](_state) {
    _state.contact_cases = [];
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
