import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../mutation-types';
import StepsAPI from '../../api/steps';
import AnalyticsHelper from '../../helper/AnalyticsHelper';
import { STEPS_EVENTS } from '../../helper/AnalyticsHelper/events';

export const state = {
  steps: [],
  flowSteps: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isLoadingSteps: false,
  },
};

export const getters = {
  getUIFlags(_state) {
    return _state.uiFlags;
  },
  getSteps: _state => botId => {
    return _state.steps
      .filter(step => step.agent_bot_id === botId)
      .sort((c2, c1) => c1.created_at - c2.created_at);
  },
  getFlowSteps: _state => flowId => {
    return _state.flowSteps
      .filter(step => step.flow_id === flowId)
      .sort((c2, c1) => c1.created_at - c2.created_at);
  },
};

export const actions = {
  get: async function getSteps({ commit }, botId) {
    commit(types.SET_STEP_UI_FLAG, { isFetching: true });
    try {
      const response = await StepsAPI.get(botId);
      commit(types.SET_STEPS, response.data);
    } catch (error) {
      // Ignore error
    } finally {
      commit(types.SET_STEP_UI_FLAG, { isFetching: false });
    }
  },
  create: async function createSteps({ commit }, stepObj) {
    commit(types.SET_STEP_UI_FLAG, { isCreating: true });
    try {
      const response = await StepsAPI.create(stepObj);
      commit(types.ADD_STEP, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_STEP_UI_FLAG, { isCreating: false });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.SET_STEP_UI_FLAG, { isUpdating: true });
    try {
      const response = await StepsAPI.update(id, updateObj);
      AnalyticsHelper.track(STEPS_EVENTS.UPDATE_STEP);
      commit(types.EDIT_STEP, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_STEP_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, id) => {
    commit(types.SET_STEP_UI_FLAG, { isDeleting: true });
    try {
      await StepsAPI.delete(id);
      AnalyticsHelper.track(STEPS_EVENTS.DELETE_STEP);
      commit(types.DELETE_STEP, id);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_STEP_UI_FLAG, { isDeleting: false });
    }
  },
  getFlowSteps: async ({ commit }, { flow_id }) => {
    commit(types.SET_STEP_UI_FLAG, { isLoadingSteps: true });
    try {
      commit(types.CLEAR_FLOW_STEPS);
      const response = await StepsAPI.getFlowSteps(flow_id);
      commit(types.SET_FLOW_STEPS, response);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_STEP_UI_FLAG, { isLoadingSteps: false });
    }
  },
};

export const mutations = {
  [types.SET_STEP_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.ADD_STEP]: MutationHelpers.create,
  [types.SET_STEPS]: MutationHelpers.set,
  [types.EDIT_STEP]: MutationHelpers.update,
  [types.DELETE_STEP]: MutationHelpers.destroy,

  [types.SET_FLOW_STEPS](_state, { data }) {
    _state.flowSteps = data;
  },
  [types.CLEAR_FLOW_STEPS](_state) {
    _state.flowSteps = [];
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
