/* global axios */
import ApiClient from './ApiClient';

class BroadcastsAPI extends ApiClient {
  constructor() {
    super('broadcasts', { accountScoped: true });
  }

  getMessages(broadcast_id) {
    let result = axios.get(`${this.url}/${broadcast_id}/messages`);
    return result;
  }

  getBroadcastReports(broadcast_id) {
    return axios.get(`${this.url}/${broadcast_id}/broadcastsReports`);
  }
}

export default new BroadcastsAPI();
