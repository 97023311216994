/* global axios */
import ApiClient from './ApiClient';

class StepsAPI extends ApiClient {
  constructor() {
    super('steps', { accountScoped: true });
  }

  getFlowSteps(flow_id) {
    let result = axios.get(`${this.url}/flows/${flow_id}/steps`);
    return result;
  }

  getAllSteps(bot_id) {
    let result = axios.get(`${this.url}/bots/${bot_id}/steps`);
    return result;
  }
}

export default new StepsAPI();
