import { frontendURL } from '../../../../helper/URLHelper';

const conversations = accountId => ({
  parentNav: 'conversations',
  routes: [
    'home',
    'inbox_dashboard',
    'inbox_conversation',
    'conversation_through_inbox',
    'notifications_dashboard',
    'label_conversations',
    'conversations_through_label',
    'team_conversations',
    'conversations_through_team',
    'conversation_mentions',
    'conversation_through_mentions',
    'conversation_participating',
    'conversation_through_participating',
    'folder_conversations',
    'conversations_through_folders',
    'conversation_unattended',
    'conversation_through_unattended',
    'conversation_open',
    'conversation_through_open',
    'conversation_resolved',
    'conversation_through_resolved',
    'conversation_pending',
    'conversation_through_pending',
  ],
  menuItems: [
    {
      icon: 'chat',
      label: 'ALL_CONVERSATIONS',
      key: 'conversations',
      toState: frontendURL(`accounts/${accountId}/dashboard`),
      toolTip: 'Conversation from all subscribed inboxes',
      toStateName: 'home',
    },
    {
      icon: 'person',
      label: 'OPEN_CONVERSATIONS',
      key: 'conversation_open',
      toState: frontendURL(`accounts/${accountId}/open/conversations`),
      toolTip: 'Open conversation from all subscribed inboxes',
      toStateName: 'conversation_open',
    },
    {
      icon: 'bot',
      label: 'PENDING_CONVERSATIONS',
      key: 'conversation_pending',
      toState: frontendURL(`accounts/${accountId}/pending/conversations`),
      toStateName: 'conversation_pending',
    },
    {
      icon: 'mail-unread',
      label: 'UNATTENDED_CONVERSATIONS',
      key: 'conversation_unattended',
      toState: frontendURL(`accounts/${accountId}/unattended/conversations`),
      toStateName: 'conversation_unattended',
    },
    {
      icon: 'mention',
      label: 'MENTIONED_CONVERSATIONS',
      key: 'conversation_mentions',
      toState: frontendURL(`accounts/${accountId}/mentions/conversations`),
      toStateName: 'conversation_mentions',
    },
    {
      icon: 'checkmark',
      label: 'RESOLVED_CONVERSATIONS',
      key: 'conversation_resolved',
      toState: frontendURL(`accounts/${accountId}/resolved/conversations`),
      toStateName: 'conversation_resolved',
    },
  ],
});

export default conversations;
